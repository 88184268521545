<template>
    <div class="hidden lg:flex">
        <div
            :class="sidebarClasses"
            style="
                border-top: 8px solid #2196f3;
                z-index: 20;

                overflow-y: scroll;
                box-shadow:
                    0px 0px 15px 0px rgba(0, 0, 0, 0.08),
                    0px 7px 12px 0px rgba(67, 0, 99, 0.19);
            "
        >
            <h2 @click="showSidebar = !showSidebar" style="cursor: pointer" class="text-lg font-medium mb-4 flex flex-row items-center">
                <img loading="lazy" decoding="async" src="../assets/Images/right_arrow.svg" style="width: 25px; height: 25px; transform: rotate(180deg)" />
                Let us show you around.
            </h2>
            <ul>
                <li
                    v-for="(item, index) in checklist"
                    :key="index"
                    style="
                        position: relative;
                        display: flex;
                        padding: 16px 16px 8px 16px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 12px;
                        border-radius: 8px;
                        border: 1px solid #e1e1ee;
                        margin-bottom: 8px;
                        box-shadow:
                            0px 1px 1px 0px rgba(0, 0, 0, 0.12),
                            0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                    "
                >
                    <div class="w-full flex flex-row">
                        <span
                            style="
                                display: flex;
                                width: 24px;
                                height: 24px;
                                padding: 5px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                background-color: #e1ecff;
                                color: #000;
                                border-radius: 50%;
                            "
                        >
                            <p v-if="item.progress < 100">{{ index + 1 }}</p>
                            <img loading="lazy" decoding="async" v-else src="../assets/Images/icons/check-circle.svg" style="width: 15px; height: 15px" />
                        </span>
                        <span class="ml-2 text-gray-700">
                            <a v-if="item.url" @click="tooltip(index)" style="text-decoration: none; cursor: pointer">{{ item.label }}</a>
                            <span v-else>{{ item.label }}</span>
                        </span>
                        <span style="position: absolute; top: 16px; right: 16px">
                            <img v-if="show[index]" style="cursor: pointer; width: 15px" @click="togglePlans(index)" src="../assets/arrow-up.png" />
                            <img v-else style="cursor: pointer; width: 15px" @click="togglePlans(index)" src="../assets/arrow-down.png" />
                        </span>
                    </div>
                    <label class="inline-flex items-center progress">
                        <input
                            type="range"
                            :value="item?.progress"
                            disabled
                            id="success-slider"
                            :style="{
                                background: `linear-gradient(90deg, #2196f3 ${item?.progress}%, #f4f7fe ${item?.progress}%)`,
                            }"
                        />
                    </label>
                    <label class="labels" v-if="item.progress == 100"> 1 of 1 </label>
                    <label class="labels" v-if="item.progress == 50"> 1 of 2 </label>
                    <label class="labels" v-if="item.progress == 0"> 0 of 1 </label>

                    <transition name="slide-toggle">
                        <img v-if="show[index]" :src="require(`../assets/onBoardingGIFs/${img[index]}`)" style="width: 100%" />
                    </transition>
                </li>
            </ul>
        </div>
        <button
            class="flex flex-row justify-between items-center w-fit border- border-red-500 fixed right-0 transform -translate-y-1/2 lg:mr-[-2%] mr-[-6%] top-1/2 ml-4 px-4 py-2 text-white bg-[#2196f3] strong rounded rotate-[-90deg] transition-transform duration-500"
            style="boder-radius: 20px; z-index: 30"
            :class="{
                'translate-x-[-732%]': showSidebar,
                'translate-x-0': !showSidebar,
            }"
            @click="showSidebar = !showSidebar"
        >
            <svg
                width="10"
                height="15"
                viewBox="0 0 11 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                :class="{
                    'rotate-90': showSidebar,
                    'rotate-[-90deg]': !showSidebar,
                }"
            >
                <path d="M2 2L9 8L2 14" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <p class="font-medium my-2 mx-4">Guidance</p>
        </button>
    </div>
</template>

<script>
import { useStore } from "../store/index";
import axios from "axios";
import introJs from "intro.js";
import "intro.js/introjs.css";
export default {
    name: "SideBarOnBarding",
    data() {
        return {
            showSidebar: false,
            candidatesCount: null,
            imagePath: require(`../assets/onBoardingGIFs/newProject.gif`),
            imagePath1: require(`../assets/onBoardingGIFs/inviteCoworker.gif`),
            companyAssessmentsLength: 0,
            show: [false, false, false, false, false],
            img: ["newProject.gif", "createAssessment.gif", "inviteCandidate.gif", "view-result.gif", "inviteCoworker.gif"],
            // companyProjects: this.Store.getProjects,
            // checklist: [
            //     { label: "Create new project", progress: this.Store.getProjects.length > 0 ? 100 : 0 },
            //     { label: "Create new Assessment", progress: 50 },
            //     { label: "Send assessment", progress: 0 },
            //     { label: "View results", progress: 0 },
            //     { label: "Invite team", progress: 0 },
            // ],
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },

    computed: {
        getProjectsLength() {
            return this.Store.getProjects.length;
        },
        getProjectAssessmentsLength() {
            return this.Store.getProjects.map((project) => {
                if (project.assessments.length > 0) {
                    return project.assessments.length;
                }
            });
        },
        checklist() {
            return [
                { label: "Create new project", progress: this.getProjectsLength > 0 ? 100 : 0, showImage: true, url: true },
                //  { label: "Create new Assessment", progress: this.companyAssessmentsLength > 0 ? 100 : 0, showImage: false },
                { label: "Send assessment", progress: this.candidatesCount > 0 ? 100 : 0, showImage: false, url: false },
                { label: "View results", progress: 0, showImage: false, url: false },
                { label: "Invite team", progress: 0, showImage: false, url: true },
            ];
        },

        sidebarClasses() {
            return [
                "fixed",
                "top-0",
                "right-0",
                "bottom-0",
                "lg:w-64",
                "w-11/12",
                "bg-white",
                "shadow",
                "p-4",
                "transform",
                "transition-transform",
                "duration-500",
                "w-width",
                this.showSidebar ? "translate-x-0" : "translate-x-full",
            ];
        },
    },
    watch: {
        candidatesCount(newVal) {
            // Update the computed property when candidatesCount changes
            console.log({ newVal });
            this.checklist;
        },
        companyAssessmentsLength(newVal) {
            // Update the computed property when getProjectAssessmentsLength changes
            console.log({ newVal422342342342: newVal });
            this.checklist;
        },
    },
    methods: {
        togglePlans(index) {
            console.log(`Toggling image for item at index: ${index}`);
            this.show[index] = !this.show[index];
        },
        tooltip(index) {
            if (this.$route.fullPath.includes("dashboard")) {
                if (index == 0) {
                    this.showSidebar = false;
                    // Initialize Intro.js
                    const intro = introJs();

                    // Set Intro.js options
                    intro.setOptions({
                        steps: [
                            {
                                element: document.getElementById("targetElement"),
                                // <img src="${this.imagePath}" alt="Image Description">
                                intro: `<h3>New Project</h3><p> The objective is to provide clear, concise, and context-specific guidance directly within the user interface,</p>`,
                                position: "bottom",
                            },

                            // Add more steps as needed
                        ],
                    });

                    // Start the introduction
                    intro.start();
                } else {
                    if (index == 3) {
                        this.showSidebar = false;
                        // Initialize Intro.js
                        const intro = introJs();

                        // Set Intro.js options
                        intro.setOptions({
                            steps: [
                                {
                                    element: document.getElementById("targetElement1"),
                                    // <img src="${this.imagePath1}" alt="Image Description">
                                    intro: `<h3>Invite Co-worker</h3><p>Here you can find how to invite co-worker to the project </p>`,
                                    position: "bottom",
                                },

                                // Add more steps as needed
                            ],
                        });

                        // Start the introduction
                        intro.start();
                    }
                }
            } else {
                this.showSidebar = false;
                this.$router.push({ path: "/dashboard", query: { t: index } });
            }
        },
    },
    mounted() {
        axios
            .get("https://server.go-platform.com/company/invitations", {
                withCredentials: true,
            })
            .then((response) => {
                if (response.status == 400) {
                    this.message = "you need to send an assessment to candidates first to view this page";
                    this.isVisible = true;
                    setTimeout(() => {
                        this.isVisible = false;
                    }, 6000);
                    return;
                }
                this.candidatesCount = response.data.invitationsSent.length;
                setTimeout(() => {
                    this.Store.getProjects.map((project) => {
                        if (project.assessments.length > 0) {
                            this.companyAssessmentsLength = project.assessments.length;
                            return;
                        }
                    });
                }, 2000);
                console.log({ candidatesCount: this.candidatesCount });
            })
            .catch((error) => {
                console.log(error);
                return;
            });
    },
};
</script>

<style scoped lang="scss">
.list-item {
    display: flex;
    padding: 16px 16px 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid #e1e1ee;
    margin-bottom: 8px;
    box-shadow:
        0px 1px 1px 0px rgba(0, 0, 0, 0.12),
        0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.icon {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #e1ecff;
    color: #000;
    border-radius: 50%;
}

.icon img {
    width: 15px;
    height: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

/* Slide Up and Slide Down Transitions */
.slide-toggle-enter-active,
.slide-toggle-leave-active {
    transition: max-height 0.5s ease;
}

.slide-toggle-enter,
.slide-toggle-leave-to {
    max-height: 0;
    overflow: hidden;
}

.progress {
    width: 100%;
    border-radius: 90px;
    background-color: #f4f7fe;
}

#success-slider {
    -webkit-appearance: none;
    appearance: none;
    // background: linear-gradient(90deg, #2196f3 70%, #fff 70%);
    // border: 1px solid #2196f3;
    background-color: #f4f7fe;
    border-radius: 90px;
    // margin-right: 48px;
    width: 100%;
    height: 10px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    // margin-left: -1px;
    border-radius: 50%;
    background: #2196f3;
    border: 3px solid #2196f3;
    cursor: pointer;
    transform: translateX(-50%);
}

#success-slider[value="0"]::-webkit-slider-thumb {
    display: none;
}

.labels {
    color: var(--Text, #2e0b34);
    /* Xsmall */
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.w-width {
    width: 50rem;
}

.introjs-tooltip {
    min-width: 400px;
    max-width: 400px;
}
</style>
